import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import cn from 'classnames';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import styles from './PartnerLogo.module.scss';

/**
 * Props for `PartnerLogo`.
 */
export type PartnerLogoProps = SliceComponentProps<Content.PartnerLogoSlice>;

/**
 * Component for "PartnerLogo" Slices.
 */
const PartnerLogo = ({ slice }: PartnerLogoProps): JSX.Element => {
  const { items, primary } = slice;
  const { background_color, title } = primary;
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={cn(styles.root, 'wide-container', styles[bg || background_color], 'color-changing-section')}
      data-background={background_color}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.mediaWrap}>
          {items.map(item => (
            <div key={item.image.id} className={styles.media}>
              <PrismicNextImage field={item.image} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerLogo;
